import {useState , useEffect, Fragment} from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Card, CardActions, CardContent, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Search, SearchOutlined } from "@mui/icons-material";
import { StudentsList } from './StudentsList';
import { LoadingSpinner } from './ui/LoadingSpinner';
import * as Yup from 'yup';
import {  Formik , useField, useFormikContext } from "formik";

async function fileComplaint(setisError , setError  ,setIsLoading , setStudentData ,values,endpoint){
  try{
    setisError(false);
    setIsLoading(true);
    const response= await fetch(`${endpoint}/complaint/save`,{
      method : "POST",
      headers: { "Content-Type": "application/json"}, 
      body : JSON.stringify({ 
        fullName : ""+values.fullName,
        phoneNumber :""+values.phoneNumber ,
        regNo : ""+values.registrationNumber,
        complaint : ""+values.complaint
       })
  });
    if(response.status === 200){
      
      console.log("this is working ")
      const placements = await response.json();
      
      setStudentData([placements.Message]);


    }else{
      console.log("An Error occured");
      const res = await response.json();
      throw new Error(res.Message);
    }
    setIsLoading(false);

  }catch(error){
    console.log("an error ",error);
    setIsLoading(false);
    setisError(true);
    setError({msg : error.message})
    setStudentData([]);
  }
}



export default function FileComplaintDemo(props) {

    const [searchText , setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [responseData , setResponseData] = useState([]); 
    const [isError , setisError] = useState(false);
    const [error , setError] = useState({});
    



  return (
    <Stack component={'form'}  direction='column'
          justifyContent='start'
          alignItems='center' 
          alignContent='start' 
          sx={{height:"100%" , width: '100%' , paddingTop : 5 , fontFamily: 'JetBrains Mono Variable' }}
      >
                <Typography fontWeight={500} fontSize={45} color={'primary'} sx={{mt : 0 , mb: 3}} > File Complaint </Typography>
                
                 {
                    !isLoading && responseData.length === 0 && !isError &&
                    <Formik

                    initialValues={{
                        fullName : '',
                        phoneNumber : '',
                        registrationNumber : '',
                        complaint : ''
                    }}
                    validationSchema={
                     Yup.object({
                      fullName : Yup.string().required().min(2).max(25),
                      phoneNumber : Yup.string().required().matches(/^\+251[79]\d{8}$/,{excludeEmptyString : true , message : "PhoneNumber Must Be Of The Form 09******** or 07******** "}),
                      registrationNumber : Yup.number().required().max(9999999).min(2222222),
                      complaint : Yup.string().required().max(200).min(6)
                      })
                    }
                    isInitialValid={false}
                    validateOnBlur
                    validateOnChange
  
                  
                  >
  
                     {
                        ({errors , touched  , dirty , isValid , handleBlur , handleChange , resetForm , validateForm , values}) => (
                          <Fragment>
  
                          <Stack mb={4} direction={{xs : "column" , sm : "row"}} width={{xs : "100%" , sm : "90%" , md : "80%" , lg : "70%" }}  
                          justifyContent={"space-between"} alignItems={{xs : "center" , sm : "center"}} >
                        <Typography fontWeight={300} fontSize={20} color={'text.primary'} sx={{ p : 0 , mt : 0 , mb: 0}} > Full Name </Typography>
                        <TextField  
  
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='fullName'
                                    error = {!!errors.fullName  ? true : false}
                                    value={values.fullName}
                                    helperText ={!!errors ? errors.fullName : ""}
                                    FormHelperTextProps={{ sx : {fontSize : 15 }} }
                                    required
                                    placeholder="Please Provide Your Full Name / Your Name  Father Name Grand Father Name / Eg Abebe Kebede Bikila"
                                    variant="outlined"
                                    type='text'
                                    sx={{mt : 0  , p:0 , bgcolor:"white" ,  width : {xs : "80%" }  }} 
                            />
  
                      
                      </Stack>
  
                      <Stack mb={4} direction={{xs : "column" , sm : "row"}} width={{xs : "100%" , sm : "90%" , md : "80%" , lg : "70%" }}  
                          justifyContent={"space-between"} alignItems={{xs : "center" , sm : "center"}} >
                        <Typography fontWeight={300} fontSize={20} color={'text.primary'} sx={{ p : 0 , mt : 0 , mb: 0}} > Phone Number </Typography>
                        <TextField  
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='phoneNumber'
                                    error = {!!errors.phoneNumber  ? true : false}
                                    value={values.phoneNumber}
                                    helperText ={!!errors ? errors.phoneNumber : ""}
                                    FormHelperTextProps={{ sx : {fontSize : 15 }} }
                                    required
                                    placeholder="Please Provide Your Phone Number/ Like +25191009365689 or +25171009365689  "
                                    variant="outlined"
                                    type='text'
                                    sx={{mt : 0  , p:0 , bgcolor:"white" ,  width : {xs : "80%" }  }} 
                                    
                            />
  
                      
                      </Stack>
  
                      <Stack mb={4} direction={{xs : "column" , sm : "row"}} width={{xs : "100%" , sm : "90%" , md : "80%" , lg : "70%" }}  
                          justifyContent={"space-between"} alignItems={{xs : "center" , sm : "center"}} >
                        <Typography fontWeight={300} fontSize={20} color={'text.primary'} sx={{ p : 0 , mt : 0 , mb: 0}} > Registration Number </Typography>
                        <TextField  
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='registrationNumber'
                                    error = {!!errors.registrationNumber  ? true : false}
                                    value={values.registrationNumber}
                                    helperText ={!!errors ? errors.registrationNumber : ""}
                                    FormHelperTextProps={{ sx : {fontSize : 15 }} }
                                    required
                                    placeholder="Please Provide Your Registration Number / Eg 2635457"
                                    variant="outlined"
                                    type='number'
                                    sx={{mt : 0  , p:0 , bgcolor:"white" ,  width : {xs : "80%" }  }} 
                                    
                            />
  
                      
                      </Stack>
  
                     
                      <Stack mb={4} direction={{xs : "column" , sm : "row"}} width={{xs : "100%" , sm : "90%" , md : "80%" , lg : "70%" }}  
                          justifyContent={"space-between"} alignItems={{xs : "center" , sm : "center"}} >
                        <Typography fontWeight={300} fontSize={20} color={'text.primary'} sx={{ p : 0 , mt : 0 , mb: 0}} > Complaint Details </Typography>
                        <TextField  
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name='complaint'
                                    error = {!!errors.complaint  ? true : false}
                                    value={values.complaint}
                                    helperText ={!!errors ? errors.complaint : ""}
                                    FormHelperTextProps={{ sx : {fontSize : 15 }} }
                                    required
                                    multiline
                                    rows={6}
                                    placeholder="Please Provide Your Full Name / Your Name  Father Name Grand Father Name / Eg Abebe Kebede Bikila"
                                    variant="outlined"
                                    type='number'
                                    sx={{mt : 0  , p:0 , bgcolor:"white" ,  width : {xs : "80%" }  }} 
                                    
                            />
  
                      
                      </Stack>
  
                      <Button
                            disabled={!isValid}
                            size="large"  variant="contained" type='button' 
                            sx={{ml : 2 , mt : {xs : 2 , sm : 0} , py : 2 , px : 4 , bgcolor : "#4379DE"  ,fontSize : 20 ,  display : "inline"}} 
                            onClick={(e) => {e.preventDefault(); fileComplaint(setisError , setError ,  setIsLoading,setResponseData,values, props.endPoint)}}
                              > Submit 
                        </Button>
  
                         </Fragment>
                        )
  
                     }
                 
                  
                    </Formik>
                 }
                

                {
                  isLoading && 
                  <LoadingSpinner/>
                }

                {
                  responseData.length === 0  && !isLoading  && isError &&
                  <Card sx={{ maxWidth: {xs : "95%" , sm : "90%" , md : "80%" , lg : "50%" } 
                                          , minWidth : "38%"  ,
                                             
                                            mt : 5 , p : 2 , bgcolor : "#DFEFFF" 
                            }}>
                  <CardContent>
                    <Typography component={'p'} lineHeight={2.2}  variant="h6" color="text.dark" maxWidth={"85%"}>
                        
                        <Typography
                           display={'inline'} component={'span'} lineHeight={2.2}
                            variant="h5" color="text.dark" fontSize={30} fontWeight={700}>
                                Sorry !!
                        </Typography>  <br/>
                            {error.msg}!!  
                                <br/> <br/>
                    </Typography>
                  </CardContent>
                  <CardActions sx={{display : "flex" , justifyContent : "end"}}>
                    <Button onClick={() => { setisError(false); setResponseData([]);  }} variant='contained'   size="large" sx={{padding : 0 , px : 4 , fontSize : 25 , textTransform : 'none' , letterSpacing : 4 }} >Try Again</Button>
                   </CardActions>
                  </Card> 
               }

                {
                  responseData.length > 0  && !isLoading  && !isError &&
                  <Card sx={{ maxWidth: {xs : "95%" , sm : "90%" , md : "80%" , lg : "50%" } 
                                          , minWidth : "38%"  ,
                                             
                                            mt : 5 , p : 2 , bgcolor : "#DFEFFF" 
                            }}>
                  <CardContent>
                    <Typography component={'p'} lineHeight={2.2}  variant="h6" color="text.dark" maxWidth={"85%"}>
                        
                        <Typography
                           display={'inline'} component={'span'} lineHeight={2.2}
                            variant="h5" color="text.dark" fontSize={30} fontWeight={700}>
                                Success !
                        </Typography>  <br/>
                            {responseData[0]}!!  
                                <br/> <br/>
                    </Typography>
                  </CardContent>
                  <CardActions sx={{display : "flex" , justifyContent : "end"}}>
                    <Button onClick={() => { setisError(false); setResponseData([]);   }} variant='contained'   size="large" sx={{padding : 0 , px : 4 , fontSize : 25 , textTransform : 'none' , letterSpacing : 4 }} >Return</Button>
                   </CardActions>
                  
                  </Card> 
               }
               
               
               



                 
                
                                       
     
    </Stack>
  );
}
 