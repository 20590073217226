import React, {useState, useEffect, Fragment} from 'react';
import './App.css';
import { Box, Tab, Tabs, createTheme } from '@mui/material';
import { LoadingSpinner } from './components/ui/LoadingSpinner';
import { StudentsList } from './components/StudentsList';
import DataGridDemo from './components/DataGridWithSearch';
import SearchPlacementDemo from './components/SearchWithDescription';
import { ThemeProvider } from '@emotion/react';
import { local, prod } from './Util/constants';
import FileComplaintDemo from './components/FileComplaint';



function App() {

  const appTheme  = createTheme({
    palette : {
      primary : {
        "light" : "#CFDFF3",
        "main" : "#3275C8",
        "dark" : "#275C9D" , 
        "contrastText" : "#FFFFFF",
        
      },
      secondary : {
        "light" : "#EFEFF5",
        "main" : "#D3D3D3",
        "dark" : "#707070" ,
        "contrastText" : "#707070"
      },
      text : {
        "primary" : "#000000",
        "secondary" : "#FFFFFF",
        "disabled" : "#FFF2E8"
      },

      
      
    },
   
    typography : {
      allVariants:{
        fontFamily : "roboto",
        letterSpacing : 1,
        fontSize : 20,
        color : "black"
      },
      h1 : {
        fontWeight : 800,
        fontSize : 80,
        
      },
      h2 : {
        fontWeight : 700,
        fontSize : 60,
        
      },
      h3 : {
        fontWeight : 600,
        fontSize : 40,
        
      },
      h4 : {
        fontWeight : 500,
        fontSize : 20,
        
      },
      h5 : {
        fontWeight : 400,
        fontSize : 18,
        
      },
      h6 : {
        fontWeight : 300,
        fontSize : 16,
        
      },
      subtitle1: {
        fontWeight : 200,
        fontSize : 16,
      },
      subtitle2: {
        fontWeight : 100,
        fontSize : 18,
      },
      body1 : {
        fontWeight : 300,
        fontSize : 14.5,
      },
      body2 : {
        fontWeight : 400,
        fontSize : 12,
      },
      caption : {
        fontWeight : 300,
        fontSize : 3,
      },
      overline : {
        fontWeight : 200,
        fontSize : 5,
      },
      
      
      

    }
   
  })


  const endpoint = process.env.NODE_ENV === "production" ? prod : local;

  console.log(endpoint);
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [activeTab , setActiveTab] = useState(0);

/*   useEffect(() => {
    async function getStudentsList(){
      try{
        setIsLoading(true);
        const response= await fetch(`${endpoint}/placement/list`);
        if(response.status === 200){
          
          const placements = await response.json();
        
          setStudentData(placements.Response.Result);
        }else{
          throw new Error("Failed to fetch placements.... ");
        }
        setIsLoading(false);

      }catch(error){
       
        setIsLoading(false);
        setStudentData(null);
      }
    }

   // getStudentsList();
  }, []) */

  const handleTabChange = (event ,value) => {
    setActiveTab(value);
    return ;
  }


  return (
    <ThemeProvider theme={appTheme}>
        <Box sx={{ margin : 3  ,height : "100%" }}>
{/*     {isLoading ? 
      <LoadingSpinner/> :
     <Box> 
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="full width tabs example"
            sx={{mb:2}}
            >
            <Tab disableRipple label="Placement With List" id={`full-width-tab-${0}`} aria-controls= {`full-width-tabpanel-${0}`} sx={{color : "primary.dark"}}/>
            <Tab disableRipple label="Placement Search" id={`full-width-tab-${1}`} aria-controls= {`full-width-tabpanel-${1}`} sx={{color : "primary.dark"}}/>
        </Tabs>

        <div >
            {activeTab ===  0 && <DataGridDemo endPoint = {endpoint} /> }
            {activeTab ===  1 && <SearchPlacementDemo endPoint = {endpoint} />}
        </div>
        
        </Box>
  

    }
 */}

{isLoading ? 
      <LoadingSpinner/> : <FileComplaintDemo  endPoint = {endpoint}/>
}                    
         </Box>
    </ThemeProvider>
  );
}

export default App;
